body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination {
  display: flex;
  padding-left: 0;
  position: revert;
  list-style: none;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: baseline;
  flex-direction: row;
}

.page-item {
  margin: 0 5px;
}

button.page-link {
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  color: #211919;
}

.page-item.active .page-link {
  border-color: rgb(230, 91, 91);
  background-color: rgb(235, 95, 67);
  color: white;
}

.page-link[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.heroSection {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../src/Assets/random_images/image-1.webp");
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  width: 100%;
}

.headerRandomImages{
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../src/Assets/random_images/image-2.webp");
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  width: 100%;
  height:500px;
}

.feature {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
}

.navbar_links_color{
   color: #e60023;
   font-weight: bolder;
   text-decoration: none;
   font-size: x-large;
}

.active_color{
  background-color: black;
  color: white;
  border: 2px solid black;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 20px;
  padding: 8px;
 
  
}

a:hover{
  text-decoration: none;
   color: #d4d4d4;
}

.dropdwon-nav-color{
 color: white !important;
}

.active{
  text-decoration: none;
  border: none;
  padding: 6px;
  border-radius: 10px;
  background: #ffffff;
  color: rgb(0, 0, 0); 
}

.inactive{
text-decoration: none;
color: white;
}

.login_backround{
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(https://source.unsplash.com/featured/?music);
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  width: 100%;
}
.login-card
{
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0,0,0,.3);
}
.login-card label
{
  display: block;
  margin-bottom: 3px;
  font-size: 14px;
}
.login-head
{
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -1.2px;
}

.login-field
{
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 16px;
  border: 2px solid #cdcdcd;
  margin-bottom: 20px;
}
.pass-icon
{
  position: absolute;
  top: 33px;
  right: 12px;
  z-index: 2;
}
.forgot-link
{
  text-decoration: none;
  color: black;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}
.or-text
{
  margin: 10px 0;
  text-align: center;
  color: #6e6969;
  font-weight: 900;
  font-size: 500;
}
.login-btn
{
  width: 100%;
  padding: 8px;
  border-radius: 30px;
  background: #e7131b;
  border: none;
  color: #fff;
  font-size: 17px;
}
.fb-btn
{
  width: 100%;
  padding: 8px;
  font-size: 17px;
  color: #fff;
  border: none;
  border-radius: 30px;
  background: #00367c;
}

section.login-page {
  min-height: calc(100vh - 112px);
}

span.pass-icon {
  background: #fff;
  padding: 3px 6px;
}

.error_register{
  margin-top: -16px;
  color: red;
  font-size: smaller;
}
.already-class {
  font-size: 15px;
    text-align: center;
    margin: 10px 20px;
    font-weight: 400;
    color: #565656;
}  
.signin-text {
  color: #e7131b;
  text-decoration: none;
  margin-left: 5px;;
}
.signin-text:hover {
  color: #e7131b;
  text-decoration: none;
  margin-left: 5px;
}
.signin-check-box-lable{
  margin-left: 5px;
  font-size: 14px;
  color: #6a6a6a;
}
main section.login_backround {
  min-height: calc(100vh - 112px);
}

ul.dropdown-menu.custom-dropdown {
  left: auto;
  right: 0;
}

ul.dropdown-menu.custom-dropdown li.dropdown-item a {
  text-decoration: none;
  color: #fff;
}


section.filter-bar {
  background-color: #59558e;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

section.filter-bar button {
  padding: 13px;
  border: none;
  background-color: #59558e;
  color: white;
}

section.filter-bar button.grid-btn-selected {
  background-color: #362c66;
}

main {
  min-height: calc(100vh - 112px);
  display: inline-block;
  width: 100%;
  padding: 50px 0 0;
}


/* Filter Css */

@keyframes filter-slider {
  from {transform: scaleY(0);}
  to {transform: scaleY(1);}
}

@keyframes filter-slider-close {
  from {transform: scaleY(1);}
  to {transform: scaleY(0);}
}
.filter-view .bg-black.active {
  animation: filter-slider 0.6s normal forwards ease-in-out;
  height: 100%;
  opacity: 1;
  display: block;
}

.filter-view .bg-black {
  transition: 0.4s ease-in-out;
  animation: filter-slider-close 0.6s normal forwards ease-in-out;
  border-radius: 0;
  border: none;
  transform-origin: top center;
  height: 0;
  opacity: 0;
  display: none;
}

.loader-image{
  max-width: 28px;
}

/* modall css */


@keyframes modal-popup {
  0% {opacity: 0;}
  100% {opacity: 1;}
  
}

.modal-agreement-form {
  width: 90% !important;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
  max-height: 80vh;
  overflow: auto;
}

.modal-form {
  max-width: 700px;
  width: 100% !important;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
  max-height: 80vh;
  overflow: auto;
}

.modal-form input {
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  font-size: 14px;
  margin-bottom : 12px;
}
.edit-venue-page .modal-form input {
  border-radius: 5px;
    border: 1px solid #cdcdcd;
    font-size: 14px;
    margin-bottom: 12px;
}
.edit-venue-page .modal-form {
  max-width: 700px;
  width: 100% !important;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
  height: 500px;
  overflow-y: scroll;
}

.modal-form button.login-btn {
  width: auto;
  margin: 0 6px;
  display: block;
  padding: 6px 50px;
}

.submission-form-popup {
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: lowercase;
  background: #00000047;
  color: #fff;
  line-height: normal;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 0;
  z-index: 9;
}

.modal-form  label {
  font-size: 11px;
  text-transform: uppercase;
  color: #626262;
}

.MuiBackdrop-root.MuiModal-backdrop.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  backdrop-filter: blur(7px);
  background-color: #00000003;
}

/*artist list  */
.artist-heading {align-items: center;}

.artist-heading h3 {
    margin: 0;
    line-height: normal;
}

.artist-heading button {
  display: flex;
  align-items: center;
  background: transparent !important;
  color: #000;
  border: 2px solid black;
  padding: 0px;
  padding-left: 6px;
  padding-right: 18px;
}

.inner-artists .artist-img {
  overflow: hidden;
  position: relative;
  background: #fff;
}

.inner-artists .artist-img img {
  background: #fff;
  width: 100%;
  height: 210px;
  object-fit: cover;
}

.inner-artists .card {
  border: 1px solid #ededed;
  transition: 0.4s ease-in-out;
}

.artist-heading button:hover {
  color: red;
}

.inner-artists  .card-body h5.card-title {
  font-size: 18px;
}

.inner-artists  .card-body p.card-text {
  font-size: 14px;
  color: #6a6a6a;
  font-weight: 300;
}

.inner-artists .card-body a.btn.btn-danger {
  width: 100%;
  background: #dc3545;
  border-radius: 0;
  padding: 6px;
}

.inner-artists .card:hover {
  box-shadow: 0 0 36px -1px #00000026;
}


/* navbar */
nav.navbar li.nav-item a {
  padding: 8px 20px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  max-height: 34px;
  line-height: normal;
  display: flex;
  margin: auto;
}

nav.navbar ul.dropdown-menu.custom-dropdown li.dropdown-item a {
  justify-content: left;
  transition: 0.3s ease-in-out;
}

nav.navbar ul.dropdown-menu.custom-dropdown li.dropdown-item {
  padding: 0;
  margin: 0;
}

nav.navbar ul.dropdown-menu.custom-dropdown li.dropdown-item a:hover,
nav.navbar ul.dropdown-menu.custom-dropdown li.dropdown-item p.text-white:hover {
  background: #d4d4d4;
}

nav.navbar ul.dropdown-menu.custom-dropdown li.dropdown-item p.text-white {
  padding: 8px 20px;
  font-size: 14px;
  align-items: center;
  max-height: 34px;
  line-height: normal;
  display: flex;
  justify-content: left;
  transition: 0.3s ease-in-out;
  margin: 0;
}

/* landing page */

section#features .card:hover {
  box-shadow: 0 0 36px -1px #00000026;
}

section#features .card {
  transition: 0.3s ease-in-out;
}


section#benifits .card:hover {
  box-shadow: 0 0 36px -1px #00000026;
}

section#benifits .card {
  transition: 0.3s ease-in-out;
}

/* spinner  */
.spinner-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.filter-section
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background: transparent;
  border: none;
  border-radius: 0px;
}
.filterSidebar
{
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right:  0;
  padding: 0px;
  width: 340px !important;
  height: 100%;
  border: none;
  border-radius: 0px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  z-index: 10;
  transform: translateX(110%);
  transition: .4s ease;
}
.filter-section.active
{
  z-index: 9999;
}
.filter-section.active .filterSidebar
{
  transform: translateX(0%);
}
.filter-section.active:before
{
  opacity: 1;
  visibility: visible;
}
.inner-filter
{
  padding: 0px;
  height: 100%;
  background: #fff;
}
.inner-filter h4
{
  background-color: #000;
  color: #fff;
  padding: 17px 20px;
}
.filter-section:before
{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: rgba(255, 255, 255, .2);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(10px);
  transition: .4s ease;
}
.inner-form-filter
{
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inner-form-filter label
{
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
}
.divider
{
  opacity: .06;
}
.close_btn_filter
{
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 20px;
  transition: .4s cubic-bezier(.3,.06,.55,1);
}
.close_btn_filter:hover
{
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.range {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 200px;
  margin-top: 10px;
}
input[type=range]::-webkit-slider-runnable-track 
{
  -webkit-appearance: none;
  background: rgba(59,173,227,1);
  background: -moz-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(59,173,227,1)), color-stop(25%, rgba(87,111,230,1)), color-stop(51%, rgba(152,68,183,1)), color-stop(100%, rgba(255,53,127,1)));
  background: -webkit-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);background: -o-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);background: -ms-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);background: linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3bade3 ', endColorstr='#ff357f ', GradientType=1 );
  height: 2px;
}
input[type=range]:focus 
{
  outline: none;
}
input[type=range]::-moz-range-track 
{
  -moz-appearance: none;
  background: rgba(59,173,227,1);
  background: -moz-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(59,173,227,1)), color-stop(25%, rgba(87,111,230,1)), color-stop(51%, rgba(152,68,183,1)), color-stop(100%, rgba(255,53,127,1)));
  background: -webkit-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: -o-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3bade3 ', endColorstr='#ff357f ', GradientType=1 );
  height: 2px;
}
input[type=range]::-webkit-slider-thumb 
{
  -webkit-appearance: none;
  border: 2px solid;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  max-width: 80px;
  position: relative;
  bottom: 5px;
  background-color: #1d1c25;
  cursor: -webkit-grab;
  -webkit-transition: border 1000ms ease;
  transition: border 1000ms ease;
}
input[type=range]::-moz-range-thumb 
{
    -moz-appearance: none;
    border: 2px solid;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    max-width: 80px;
    position: relative;
    bottom: 5px;
    background-color: #1d1c25;
    cursor: -moz-grab;
    -moz-transition: border 1000ms ease;
    transition: border 1000ms ease;
  }
  .range.blue::-webkit-slider-thumb 
  {
    border-color: rgb(59,173,227);
  }
  .range.ltpurple::-webkit-slider-thumb 
  {
    border-color: rgb(87,111,230);
  }
  .range.purple::-webkit-slider-thumb 
  {
    border-color: rgb(152,68,183);
  }
  .range.pink::-webkit-slider-thumb 
  {
    border-color: rgb(255,53,127);
  }
  .range.blue::-moz-range-thumb 
  {
    border-color: rgb(59,173,227);
  }
  .range.ltpurple::-moz-range-thumb 
  {
    border-color: rgb(87,111,230);
  }
  .range.purple::-moz-range-thumb 
  {
    border-color: rgb(152,68,183);
  }
  .range.pink::-moz-range-thumb 
  {
    border-color: rgb(255,53,127);
  }
  input[type=range]::-webkit-slider-thumb:active 
  {
    cursor: -webkit-grabbing;
  }
  input[type=range]::-moz-range-thumb:active 
  {
    cursor: -moz-grabbing;
  }
  .filter-button
  {
    top: 17%; 
    right: 0px;
    width: auto;
    padding: 8px 8px;
    border-radius: 10px 0px 0px 10px;
    background: #000;
    color: #fff;
    cursor: pointer;
    z-index: 9;
    position: fixed;
  }
  .upcoming-event-card
  {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 15px rgb(58 58 58 / 20%);
    transition: .4s ease;
    transform: scale(1);
  }
  .upcoming-event-card:hover
  {
    transform: scale(1.05);
  }
  .upcoming-event-card .artist-img img
  {

    height: 263px;
  }
  .upcoming-event-card:before
  {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgb(0 0 0 / 98%) 38%, #000000 55%);
    z-index: -1;
  }
  .artist-info
  {
    position: relative;
    z-index: 2;
    padding: 0 20px 20px;
    color: #c3c3c3;
    box-shadow: 0px -10px 20px 16px #000000;
  }
  .artist-info .card-title
  {
    font-size: 20px;
    margin-top: 10px;
  }
  .zindex-9
  {
    z-index: 9;
  }
  .edit-icon {
    position: absolute;
    top: 14px;
    right: 50px;
    color: #000000;
    background: #e3e3e3 !important;
    padding: 8px 7px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 0 5px #8d8d8d;
    border: none !important;
  }
  .edit-icon:hover {
    background-color: #fff !important;
  }
  .dlt-icon {
    position: absolute;
    top: 14px;
    right: 10px;
    color: #000000;
    background: #e3e3e3 !important;
    padding: 8px 7px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0 0 5px #8d8d8d;
    border: none !important;
  }
  .dlt-icon:hover {
    background-color: #fff !important;
  }

  .add-event-modal .css-a0n6xq {
    max-height: 500px;
    overflow-y: scroll;
  }
  .event-search-input {
    max-width: 300px;
    width: 100%;
  }
  .event-search-icon {
    border: 1px solid #cdcdcd !important;
    margin-left: 10px !important;
    color: #000 !important;
}
/* .artist-img {
  width: 180px !important;
  height: 180px !important;
  object-fit: cover;
  border-radius: 50%;
  border: none !important;
} */
.artist-bg::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
}
.img-thumbnail {
  width: auto !important;
}
.z-index-999 {
z-index: 999 !important;
position: relative;
}
.name-artist-heading {
  margin-left: 50px;
  color: #fff;
  
}
.name-artist-para {
  margin-left: 50px;
  color: #fff;
  font-size: 14px;
}
.follow-btn {
  padding: 6px 10px !important; 
  font-size: 16px !important;
}

.svg-social-icons {
  color: #ffffff;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 3px;
  background: white;
  box-shadow: 0 0 7px 1px #e5e5e5;
}

.artist-detail-img{
  width: 180px !important;
    height: 180px !important;
    object-fit: cover;
    border-radius: 50%;
    border: none !important;
}

.social-link{
  width: 100%;
}

.border-light-color {
  box-shadow: 0 0 7px 1px #e5e5e5 !important;
  border: none !important;
}
@media screen and (max-width:768px) {

  .artist-detail-img {
    width: 100px !important;
    height: 100px !important;
  }
  .name-artist-heading {
    margin-left: 0px !important;
    font-size: 25px !important;
  }
  .name-artist-para {
    margin-left: 0px !important;
    font-size: 14px !important;
  }
  .follow-btn {
    padding: 6px 22px !important;
    font-size: 14px !important;
}
.social-link{
  max-width: 130px !important;
  width: 100%;
}
}



/* light box css start */

.headr-navs-bg {
  margin-top: -26px;
  background: #fff;
}
.nav-tabs .nav-link.active {  
  color: #da3743 !important;
  border-bottom: 2px solid #da3743!important;
  border-radius: 0px !important;
  border: none;
}
.nav-tabs .nav-link{  
  color: #000000;
}
.saying-main-heading {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
  border-bottom: 1px solid #dbdbdb;
  padding: 15px 0px;
  margin: 0;
}
.saying-sub-heading {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 10px 0px; 
}
.saying-para {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5rem;
}
.stars-heading {
  font-size: 12px;
  margin: 0;
  padding: 0;
}
.point-text {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}
.point-food-text {
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.people-saying ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
}
.people-saying ul li {
    border-right: 0.0625rem solid #d8d9db;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    text-align: center;
}
.progress {
  border-radius: 0px !important;
}

.progress-bar-list span {
  margin-right: 5px;
}

.progress-bar-list .progress {
  width: 100%;
}

.tabs-section {
  box-shadow: 0 0 6px #e7e7e7;
  margin-bottom: 30px;
}
.ot {
  flex-shrink: 0;
  height: 48px;
  margin: 0 8px 8px;
  width: 48px;
  background-color: #bb6acd;
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}
.opentable {
  font-size: .875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
}
.food-was {
  line-height: 24px;
  max-height: 72px;
  overflow: hidden;
  -webkit-line-clamp: 3;
  font-size: 14px;
}
.blank-box {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px #2d333f33;
  padding: 16px;
  width: 430px;
  height: 455;
  margin-top: -25px;
}
.blank-second-box {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px #2d333f33;
  padding: 16px;
  width: 100%;
  height: 180px;
  margin-top: 40px;
}

.rbc-calendar {
  width: 100%;
  height: 500px !important;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}
.slick-prev{
  left: -25px;
    position: absolute !important;
    top: 50% !important;
    left: -5% !important;
    bottom: auto !important;
    height: 20px !important;
    width: 20px !important;
    z-index: 9999999 !important;
}

.slider-popup{
  background-color: rgba(0, 0, 0, 0.459) !important;
  backdrop-filter: blur(7px);
}

.modal-slider {
  max-height: 500px !important;
  max-width: 700px;
  width: 100% !important;
  height: 100%;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
  background-color:#000 !important;
}
.slider-popup .css-a0n6xq {
  background-color:#000 !important;
  padding: 0px !important;
}

.slider-images{
  width: 100%;
  height: 100%;
  min-width: 700px;
  min-height: 500px;
  max-height: 500px;
}
.slick-next:before {
  font-size: 30px !important;
}
.slick-prev:before {
  font-size: 30px !important;
}

.slick-arrow{
  background-color : transparent !important ;
}
.venue-name-card-para {
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}
.artist-name-card {
  font-size: 14px;
}
.venue-event-img {
    height: 100%;
    object-fit: cover;
    max-width: 300px;
    width: 100%;
    max-height: 250px;
}
.venue-event-card {
  padding: 8px;
  box-shadow: 0 0 5px #dbdbdb;
  border-radius: 10px;
}
.venue-events .card-body {
  padding: 0px !important;
}
.venue-events .card {
  padding: 0px !important;
}

.modal-event-popup {
  max-height: 250px !important;
  max-width: 500px;
  width: 100% !important;
  height: 100%;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
  
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.rbc-toolbar button:hover {
    color: #fff !important;
    background-color: #557ae8 !important;
    border-color: #557ae8 !important;
}
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #557ae8 !important;
    border-color: #557ae8 !important;
    color: #fff !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color:green !important;
  color: #ffffff !important  ;
}
.rbc-header {
  color: #fff !important;
  background-color: #000 !important;
}
.rbc-toolbar {
  padding: 10px 10px !important;
  margin-bottom: 0px !important;
  background-color: #000 !important;
  
}

.rbc-day-bg {
  background: #ffffff !important;
  color: #fff !important;
}
.rbc-calendar {
border: 1px solid #b36464 !important;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
  background: #b36464 !important;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
  background: #000 !important;
  color: #fff !important;
}
.rbc-btn-group button {
background-color: #fff !important;
color: #000 !important;
}

.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
  color: white;
}

.rbc-off-range {
  color: #ffffff !important;
  
}

.rbc-off-range-bg {
  background: #ebebeb !important;
  color: #fff !important;
}
.followers-venue {
  position: absolute;
  right: 0;
  left: auto;
  top: -30px;
  z-index: 999;
  padding: 6px 20px;
  font-size: 15px;
  border-radius: 4px 4px 0px 0px;
  color: #fff;
  
}

.sec5-istimg-height {
  max-height: 240px;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border: 2px solid #3c3c3c;
}
.slider-img-height{
  width: 100%;
  max-height: 120px;
  object-fit: cover;
  height: 100%;
  border: 2px solid #3c3c3c;
}
.photo-gallary-slide {
  max-height: 370px;
}
.sec3-istimg-height {
  max-height: 244px;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border: 2px solid #3c3c3c;
}
.review-para {
  font-size: 12px;
}


.venue-artist-img {
  height: 100%;
  object-fit: cover;
  max-width: 180px;
  width: 100%;
  max-height: 180px;
}

.modal-bid-popup {
  max-height: 600px !important;
  max-width: 520px;
  width: 100% !important;
  height: 100%;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
  
}
.bid-cards {
  box-shadow: 0 0 5px #00000026;
    border-radius: 10px;
}
.bid-fields {
  width: 70%;
  margin: 0 auto;
}
.bid-fields-input {
  display: block;
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #cdcdcd;
    margin-bottom: 20px;
    outline: none;
}

.mygig-full-image{
 height: 200px;
 width: 100%;
 border-radius: 10px 10px 0px 0px;
}

.mygig-rounded-image{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: -40px;
  box-shadow: 0 0 6px 1px #00000054;
}
.gig-cards-para {
  font-size: 16px;
  color: #747474; 
  padding-left: 30px;
}
.gig-card-btn {
  font-size: 15px;
  border: none;
  border-radius: 5px;
  padding: 5px 14px;
  margin: 0 2px;
  margin-top: 10px;
  font-weight: 500;
  color: #fff;
}
.green {
  background: #009900;
  color: #fff;
  font-weight: 500;
}
.orange {
  background: #003ba7;
}
.gig-profile-card {
  box-shadow: 0 0 6px 1px #c7c7c7;
  border-radius: 10px;
}

.gig-modal-form {
  max-width: 500px;
  width: 100% !important;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
}

.gig-input-field
{
  display: block;
  width: 100%;
  padding: 4px;
  border: 2px solid #cdcdcd;
  margin-bottom: 20px;
}
.Negotiation-tab {
  margin-top: -20px;
}
.bidable-checkbox {
  width: 15px;
  height: 15px;
  margin: 0 5px;
}
.bidable-checkbox-heaeding {
  font-size: 15px !important;
  text-transform: none !important;
}

.table-text-font-size{
  font-size: 14px;
  padding: 5px 5px;
}

.accept-status{
  padding: 2px 16px;
    border-radius: 4px;
    color: green !important;
    background: #0080000f;
}
.reject-status {
  padding: 2px 16px;
  border-radius: 4px;
  background-color: #dc354529 !important;
}
.pending-status {
  padding: 2px 16px;
    border-radius: 4px;
    background-color: #dfa70021 !important;
    color: #dfa700! important;
}
@media screen and (max-width:1024px) {
  .gig-profile-card ul {
    font-size: 12px;
  }
  .gig-profile-card ul li span {
    font-size: 12px;
  }
  }
  .event-detail-img {
width: 100%;
  }
  .event-detail-triangle {
    width: 100%;
    height: 200px;
    background: #fff;
    clip-path: polygon(0 75%, 100% 22%, 100% 100%, 0% 100%);
    position: absolute;
    z-index: 1;
    bottom:0;
}
.event-detail-card {
    height: auto;
    position: absolute;
    max-height: 500px;
    height: inherit;
    top: 150px;
    max-width: 300px;
    width: 100%;
    border-radius: 5px;
    background-color: #6b0f1a;
    background-image: linear-gradient(315deg, #6b0f1a 0%, #b91372 74%);
    box-shadow: 0 0 3px 1px #a7a7a761;
    z-index: 2;
    right: 40px;
}

.review-form{
  max-width: 500px;
  width: 100% !important;
  border: none !important;
  border-radius: 4px;
  animation: modal-popup 0.4s normal ease-in-out;
}

.review-form input{
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  font-size: 14px;
  margin-bottom : 12px;
}

.review-form-popup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-btn
{
  width: 30%;
  padding: 8px;
  background: #e7131b;
  border: none;
  color: #fff;
  font-size: 17px;
  margin-right: 10px;
  margin-top: 10px;
}

section.hero {
  min-height: 500px;
  position: relative;
}

section.hero .content {
    position: relative;
    z-index: 1;
    color: #000;
}

section.hero:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000054;
    z-index: 0;
}

.event-detail-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.event-detail-card ul li {
  margin-bottom: 10px;
  color: #fff;
  font-weight: 300;
}

.event-detail-card ul li label {
  color: #fff;
  font-weight: bold;
  padding-right: 10px;
}

.event-detail-card ul li a {
  text-decoration: none;
  font-weight: 300;
}

.no-data-found {
  text-align: center;
  color: #c43333;
}
.event-dynamic-img {
background-repeat: no-repeat !important;
background-size: cover !important;
}


@media screen and (max-width:768px) {
  .dropdown-layout-style {
    position: absolute !important;
      left: auto !important;
      right: 160px !important;
  }
}
@media screen and (max-width:425px) {
  .dropdown-layout-style {
    position: absolute !important;
      left: auto !important;
      right: 0px !important;
  }
}

.note{
  font-size: x-small;
   margin-top: -11px;
}
.new-event-btns {
  font-size: 15px !important;
}

.media-modal .css-a0n6xq {
  padding: 15px 32px !important;
}
.media-modal .login-field {
  padding: 0px !important;
}
.media-modal .modal-form {
  max-width: 530px;
}
.media-modal .modal-form button.login-btn {
  width: auto;
  margin: 0 6px;
  display: block;
  padding: 5px 35px;
  font-size: 14px;
  border-radius: 3px !important;
}
@media screen and (max-width:768px) {
  .new-event-btns {
    margin-top: 10px !important;
  }
  .nav {
    --bs-nav-link-padding-x: 9px !important;
    --bs-nav-link-padding-y: 5px !important;
  }
  .nav-tabs .nav-link {
    font-size: 12px;
  }
}
.media-gallary-size {
  height: 181px;
  width: 181px;
  border-right: 2px solid #ffff;
  border-left: 2px solid #ffff;
  border-bottom: 2px solid #fff;
}
.media-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.media-imgs-sec {
  max-height: 60vw;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media screen and (max-width:767px) {
  .media-gallary-size {
    height: auto !important;
    width: 100% !important; 
    border-right: 2px solid #ffff;
    border-left: 2px solid #ffff;
    border-bottom: 2px solid #fff;
  }
  .media-gallary-video {
    max-height: 100% !important;
  }
}
.media-page .nav-tabs .nav-link.active {
  background-color: #da3743 !important;
  border: none!important;
  color: #fff !important;
  border-radius: 0px !important;
  border: none;
  border-radius: 5px !important;
  font-size: 12px;
  padding: 2px 16px !important;
}
.media-page .nav-tabs .nav-link {
  font-size: 12px;
  padding: 2px 16px !important;
}
.media-page .nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 0 4px #d3d3d3;
  font-weight: 500;
}
.media-page .nav-tabs .nav-link:hover {
  color: #dc3545 !important;
  background: transparent !important;
  border: none !important;
  font-weight: 500;
}
.media-page .nav-tabs .nav-link {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.media-gallary-video {
  width: 100% !important;
  border-right: 2px solid #ffff;
  border-left: 2px solid #ffff;
  border-bottom: 2px solid #fff;
  max-height: 131px;
}
.media-video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.media-audio {
  width: 100%;
  background: #f1f3f4;
  border-radius: 6px;
  box-shadow: 0 0 2px #ededed;
}

.player {
  position: relative;
  background: #f1f3f4;
  box-shadow: 0 50px 80px rgba(0, 0, 0, 0.25);
}

.player .imgbx {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
  background: #dc3545;
}
.player .imgbx img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.player audio {
  width: 100%;
  outline: none;
}
.media-gallary-audio {
  width: 100% !important;
  border-right: 2px solid #ffff;
  border-left: 2px solid #ffff;
  border-bottom: 2px solid #fff;
}
.player audio {
  height: 35px !important;
}

.media-delete-btn{
  position: absolute;
  z-index: 99;
  top: 5px;
  right: 9px;
  left: auto;
  background: #e1e1e1;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 5px;
}
.ratings-labels {
  display: flex;
  justify-content: space-between;
}