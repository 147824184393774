
.container {
  justify-content: center;
  align-items: center;
}

.material-textfield {
  position: relative;  
}
.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: #ffffff !important;
  color: #ffffff !important;
}
.rbc-toolbar button {
  color: #217ebd;
  display: list-item;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar {
  padding: 10px 10px !important;
  margin-bottom: 0px !important;
  background-color: hsla(0, 100%, 98%, 0.043) !important;
}
.rbc-btn-group {
  display: contents;
  white-space: nowrap;
  padding-right: 70px;
}
.rbc-calendar {
  width: 100%;
  height: 500px !important;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
  background-color: #ffffff;
  color: #061827;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: .1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  display: grid;
  align-content: stretch;
  justify-items: stretch;
}
.rbc-month-row {
  display: flex;
  position: relative;
  flex: 1 0;
  flex-basis: max-content;
  overflow: inherit;
  height: 100%;
  justify-content: flex-end;
 
}
rbc-time-gutter, .rbc-header-gutter {
  flex: inherit;
  color: white;
  background-color: #ffffff;
}
.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column-reverse;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1 1;
  background: #44687e !important;
  color: #fff !important;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
  background: #ffffff !important;
}
.rbc-header {
  color: #fff !important;
  background-color: #44687e !important;
  flex: content;
  max-width: 100%;
}
.rbc-time-view .rbc-allday-cell {
  width: 89%;
  height: 100%;
}
label {
  font-size: 1rem;
  left: 0;
  top: 50%;
 
  background-color: #ffffff;
  color: rgb(9, 1, 1);
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: .1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  display: grid;
  align-content: stretch;
  justify-items: end;
}
input {
  font-size: 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;  
  padding: 1rem 0.7rem;
  color: gray;
  transition: 0.1s ease-out;
}
input:focus {
  border-color: #6200EE;  
}
input:focus + label {
  color: #6200EE;
  top: 0;
  transform: translateY(-50%) scale(.9);
}
input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(.9);
}