@media (min-width: 1200px) {
    .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1140px;
        max-width: 100%;
    }
}
.row-splitter .module--full-width {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding: 50px 0 80px;
}
.container-fluid {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.row-splitter.initialized {
    display: block;
}
.row-splitter {
    box-sizing: border-box;
}
.event-detail h1 {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 20px !important;
    margin-top: 30px;;
}

.event-detail--title {
    text-align: left !important;
}
.event-detail .event-date-list ul.items {
    margin-left: 0;
    padding: 0;
    position: relative;
    margin-bottom: 10px;
}
.event-detail .event-date-list ul.items li.item:first-child {
    border-top: 1px solid #d2d2d2;
}
.event-detail .event-date-list {
    margin-top: 40px;
    margin-bottom: -15px;
}
.event-detail .event-date-list ul.items li.item {
    list-style: none;
    margin-left: 0;
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 0;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}
.component {
    box-sizing: border-box;
}
.event-detail .event-detail--disclaimer {
    margin-top: 20px;
}

.event-detail .event-date-list ul.items li.item .item__wrapper {
    align-items: center;
    display: flex;
    width: 100%;
    padding: 15px 0;
}
.is-hardrock-tampa-hollywood .event-detail .event-date-list ul.items li.item .item__wrapper .listCCard__time__wrap {
    color: #474747;
}
.event-detail .event-detail--place {
    margin-bottom: 5px;
}
.listCCard__time__wrap {
    position: relative;
    padding-left: 25px;
    margin: 0;
    padding-top: 2px;
}
.listCCard__place__wrap {
    position: relative;
    padding-left: 25px;
    margin: 0;
    padding-top: 2px;
}
.listCCard__rate__wrap {
    position: relative;
    padding-left: 25px;
    margin: 0;
    padding-top: 2px;
}
.listCCard__rate {
    display: flex;
    background-image: url("../../Assets/list_card_money.svg");
    color: #474747;
    line-height: 1.1em;
    position: relative;
    min-height: 18px;
    background-repeat: no-repeat;
}
.listCCard__time {
    display: flex;
    background-image: url("../../Assets/list_card_time.svg");
    color: #474747;
    line-height: 1.1em;
    position: relative;
    min-height: 18px;
    background-repeat: no-repeat;
}
.listCCard__place {
    display: flex;
    background-image: url("../../Assets/list_card_place.svg");
    color: #474747;
    line-height: 1.1em;
    position: relative;
    min-height: 18px;
    background-repeat: no-repeat;
}
.listCCard__genre {
    display: flex;
    color: #474747;
    line-height: 1.1em;
    position: relative;
    min-height: 18px;
    background-repeat: no-repeat;
}
.listCCard__genre__wrap {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    margin-top: 2px;
    padding-top: 2px;
}

@media (min-width: 768px) {
    .listCCard__place {
        display: flex;
        line-height: 1.1em;
        color: #474747;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
@media (min-width: 768px) {
    .listCCard__time {
        display: flex;
        line-height: 1.1em;
        color: #474747;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.event-detail .field-eventdescription {
    margin-top: 40px;
}
